<template>
    <v-container>
        <v-alert :type="alertType" v-model="alertDisplay" dismissible>{{alertMessage}}</v-alert>

        <v-row>
            <v-layout class="px-3 mb-2">
                <v-flex xs6>
                    <v-form ref="form" lazy-validation>
                        <v-flex>
                            <v-text-field class="required" style="margin-top:24px" label="Username" v-model="user.username"
                                          counter="256"
                                          :rules="[ rules.required, rules.usernameCharacters, () => !!user.username && user.username.length <= 256 || 'Max 256 characters']"
                                          maxlength="256">
                            </v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field class="required" label="Email" v-model="user.email" counter="256"
                                          :rules="[rules.required, rules.email, () => !!user.email && user.email.length <= 256 || 'Max 256 characters']"
                                          maxlength="256">
                            </v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field :type="showPassword ? 'text' : 'password'" label="Password" v-model="user.password"
                                          :rules="[ rules.password ]"
                                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword">
                            </v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field class="required" label="First Name" v-model="user.firstName"
                                          counter="128"
                                          :rules="[ rules.required, rules.nameCharacters, () => !!user.firstName && user.firstName.length <= 128 || 'Max 128 characters']"
                                          maxlength="128">
                            </v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field class="required" label="Last Name" v-model="user.lastName"
                                          counter="128"
                                          :rules="[ rules.required, rules.nameCharacters, () => !!user.lastName && user.lastName.length <= 128 || 'Max 128 characters']"
                                          maxlength="128">
                            </v-text-field>
                        </v-flex>
                        <v-divider></v-divider>
                        <v-flex>
                            <v-card-actions>
                                <v-btn color="success" depressed @click="updateUser" :disabled="updateButtonDisabled">
                                    Update
                                </v-btn>
                            </v-card-actions>
                        </v-flex>
                    </v-form>
                </v-flex>
            </v-layout>
        </v-row>

    </v-container>

</template>

<script>
    import axios from "axios";

    export default {
        name: "profile",
        components: {},
        data() {
            return {
                loading: true,
                user: { id: null, username: null, password: null, firstName: null, lastName: null },
                confirmPassword: null,
                showPassword: false,
                showConfirmPassword: false,
                updateButtonDisabled: false,
                alertType: null,
                alertMessage: null,
                alertDisplay: false,
                rules: {
                    required: value => !!value || 'Required',
                    usernameCharacters: value => {
                        const pattern = /^[a-z0-9.]+$/
                        return !value || (value && pattern.test(value)) || 'Username can contain lowercase letters, numbers and dots'
                    },
                    nameCharacters: value => {
                        const pattern = /^[A-Za-z .-]+$/
                        return !value || (value && pattern.test(value)) || 'Field can contain letters, spaces, dashes and dots'
                    },
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail'
                    },
                    password: value => {
                        const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
                        return !value || (value && pattern.test(value)) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter'
                    }
                }
            }
        },
        watch: {

        },

        created() {
            this.alertDisplay = false;
            this.fetchUser();
        },

        methods: {
            showAlert(type, message) {
                this.alertType = type;
                this.alertMessage = message;
                this.alertDisplay = true;
            },

            updateUser() {
                this.alertDisplay = false;
                if (this.$refs.form.validate()) {
                    var data = {
                        Id: this.user.id,
                        FirstName: this.user.firstName.trim(),
                        LastName: this.user.lastName.trim(),
                        Username: this.user.username.trim(),
                        Email: this.user.email.trim()
                    };

                    if (this.user.password) {
                        data.Password = this.user.password;
                    }

                    this.updateButtonDisabled = true;

                    axios.post(`Users/Update`, data)
                        .then(() => {
                            this.showAlert("success", "User updated");
                            this.updateButtonDisabled = false;
                            this.fetchUser();
                        }).catch((err) => {
                            console.log(err);
                            this.showAlert("error", "An error occured while updating the user!");
                            this.updateButtonDisabled = false;
                        }).finally(() => {
                        });
                }
            },

            fetchUser() {
                this.getUserFromApi()
                    .then(data => {
                        this.user = data.user;
                });
            },

            getUserFromApi() {
                this.loading = true;
                this.updateButtonDisabled = true;
                var userId = localStorage.getItem("userId");
                return new Promise((resolve, reject) => {
                    axios.get(`Users/GetUser/${userId}`)
                        .then((result) => {
                            this.updateButtonDisabled = false;
                            let user = result.data;
                            resolve({
                                user
                            });
                        }).catch((err) => {
                            console.log(err);
                            this.showAlert("error", "An error occured while getting the user data!");
                            reject(err);
                        }).finally(() => {
                            this.loading = false;
                        });
                })
            }
        }
    };
</script>

<style>
    .dandelion-cell {
        background-color: #ffeaab !important;
    }

    .pink-cell {
        background-color: #ffc7ce !important;
    }

    .light-green-cell {
        background-color: #d1f2d7 !important;
    }

    .required label::after {
        content: "*";
    }
</style>